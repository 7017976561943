import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Footer } from "./Footer";
import { YoutubeVideos } from "./YoutubeVideos";
import { SocialLinks } from "./SocialLinks";
import { Title } from "./Title";
import { WhatsappCommunity } from "./WhatsappCommunity";
import { HOME_VIDEOS } from "./constants";

function Home() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Main Content */}
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Title />
        <WhatsappCommunity />
        <YoutubeVideos
          videoLinks={HOME_VIDEOS}
          title="Most Watched"
          order="viewCount"
        />
        <YoutubeVideos
          videoLinks={HOME_VIDEOS}
          title="Latest Videos"
          order="date"
        />
        <SocialLinks />
      </Container>
      <Footer />
    </Box>
  );
}

export default Home;
