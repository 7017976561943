import { Box, Container, Typography } from "@mui/material";
import * as React from "react";

export const Title = () => {
  return (
    <>
      <Box sx={{ my: 4, textAlign: "center" }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Welcome to നന്മ മരം
        </Typography>
        <Typography variant="h5" color="text.secondary">
          Join our community and grow in faith together
        </Typography>
      </Box>
    </>
  );
};
