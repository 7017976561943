export const COLLECTION_NAMES = {
  SECTIONS: "sections",
  LANGUAGE: "language",
  VERSES: "verses",
  ADMIN: "admin",
};

export const DOCUMENT_NAME = {
  LANGUAGE_VERSION: "lang_version",
};
