export const MOVIES = [
  {
    id: 1,
    title: "Miracle of Marcelino Pan Y Vino",
    url: "https://www.youtube.com/watch?v=QXMr_xOBzBs",
  },
  {
    id: 2,
    title: "Varayan",
    url: "https://www.youtube.com/watch?v=8bKWgr5hg8M",
  },
];
