import { YouTube as YouTubeIcon } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { VideoCard } from "../../components/VideoCard";
import { MOVIES } from "./constants";

export const Movies = () => {
  function getYouTubeId(url) {
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <YouTubeIcon sx={{ fontSize: "2rem", color: "#FF0000", mr: 1 }} />
        <Typography variant="h4">Movies</Typography>
      </Box>
      <Grid container spacing={3}>
        {MOVIES.map((video) => (
          <VideoCard
            key={video.id}
            id={getYouTubeId(video?.url)}
            title={video.title}
          />
        ))}
      </Grid>
    </Box>
  );
};
