import { YouTube as YouTubeIcon } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VideoCard } from "../../components/VideoCard";

export const YoutubeVideos: React.FC = ({
  title = "",
  videoLinks = [],
  order = "date",
}) => {
  const [videos, setVideos] = useState(videoLinks);

  useEffect(() => {
    const API_KEY = "AIzaSyAL2vun6QoLk9HpinNJD6aixum2FMnI6Oc";
    const CHANNEL_ID = "UCLneuIp3RXNrmRgnZpI-2wA";
    const COUNT_VIDEOS = 8;

    fetch(
      `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=${order}&maxResults=${COUNT_VIDEOS}`
    )
      .then((response) => response.json())
      .then((data) => {
        setVideos(data.items);
      })
      .catch((error) => console.error("Error fetching videos:", error));
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <YouTubeIcon sx={{ fontSize: "2rem", color: "#FF0000", mr: 1 }} />
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Grid container spacing={3}>
        {videos.map((video) => (
          <VideoCard id={video?.id?.videoId} title={video.snippet?.title} />
        ))}
      </Grid>
    </Box>
  );
};
