import React from "react";
import { Container, Typography, Button, Box, Paper, Grid } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { WhatsApp, WhatsApp as WhatsAppIcon } from "@mui/icons-material";

export const WhatsappCommunity: React.FC = () => {
  const slideIn = useSpring({
    from: { opacity: 0, transform: "translateX(-50px)" },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 1000 },
  });

  const hoverAnimation = useSpring({
    from: { transform: "translateY(0)" },
    to: { transform: "translateY(-5px)" },
    config: { tension: 300, friction: 10 },
  });

  return (
    <Box sx={{ py: { xs: 6, md: 12 }, backgroundColor: "#ffffff" }}>
      <Container maxWidth="md">
        <animated.div style={slideIn}>
          <Paper
            elevation={3}
            sx={{ p: { xs: 4, md: 6 }, borderRadius: 4, textAlign: "center" }}
          >
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              sx={{
                color: "primary.main",
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
              }}
            >
              Join Our WhatsApp Community
            </Typography>
            <Typography
              variant="h6"
              paragraph
              sx={{
                mb: 4,
                color: "text.secondary",
                fontSize: { xs: "1rem", sm: "1.1rem", md: "1.25rem" },
              }}
            >
              Connect with fellow believers, share inspirational messages, and
              stay updated with our latest events.
            </Typography>
            <animated.div style={hoverAnimation}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<WhatsApp />}
                    href="https://chat.whatsapp.com/K5nGMx0mC5Y1sZ9AoGW8ej"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      py: 2,
                      px: 4,
                      fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
                      textTransform: "none",
                      // color: "#1C1678",
                      // backgroundColor: "#B6FFA1",
                    }}
                  >
                    Join നന്മ മരം Community
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<WhatsApp />}
                    href="https://chat.whatsapp.com/E4sKQmaJAT4A0GrC2loWt9"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      py: 2,
                      px: 4,
                      fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
                      textTransform: "none",
                      // color: "#1C1678",
                      // backgroundColor: "#B6FFA1",
                    }}
                  >
                    Join Nov5-Dec8 വിമലഹൃദയപ്രതിഷ്ഠ Community
                  </Button>
                </Grid>
              </Grid>
            </animated.div>
          </Paper>
        </animated.div>
      </Container>
    </Box>
  );
};
