import { PlayArrow as PlayArrowIcon } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

export const VideoCard = ({ id = "", title = "" }) => {
  const getYouTubeThumbnail = (videoId: string) => {
    return `https://img.youtube.com/vi/${id}/mqdefault.jpg`;
  };
  const getYouTubeUrl = (videoId: string) => {
    return `https://www.youtube.com/watch?v=${id}`;
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            height="157"
            image={getYouTubeThumbnail(id)}
            alt={title}
          />
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              },
            }}
            component="a"
            href={getYouTubeUrl(id)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PlayArrowIcon />
          </IconButton>
        </Box>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textDecoration: "bold",
            }}
          >
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
