export const PAGES = [
  { label: "Bible Verses", link: "/bible" },
  { label: "Wallpaper Maker", link: "/wallp" },
  { label: "Prayer", link: "/prayer" },
  { label: "Movies", link: "/movies" },
  { label: "Books", link: "/books" },
  { label: "About Us", link: "/about" },
];
export const SETTINGS = ["Profile", "Account", "Dashboard", "Logout"];

export const HOME_VIDEOS = [
  {
    id: 1,
    title: "വി.കുർബ്ബാനയെ അതി തീവ്രമായി സ്നേഹിച്ച ഒരു യൂട്യൂബറുടെ ജീവിതം",
    youtubeId: "9RiFTtzwq-w",
    url: "https://www.youtube.com/watch?v=9RiFTtzwq-w",
  },
  {
    id: 2,
    title: "Personal prayer അനുഭവം ആകാൻ ജെറിൽ ന്റെ 12 Step format",
    youtubeId: "enesML0PMdw",
    url: "https://www.youtube.com/watch?v=enesML0PMdw",
  },
  {
    id: 3,
    title: "മരണം വരെ കത്തിജ്വലിക്കുക",
    youtubeId: "SoG8o8yrPWk",
    url: "https://www.youtube.com/watch?v=SoG8o8yrPWk",
  },
  {
    id: 4,
    title: "പലവിചാരങ്ങൾ കാരണം വ്യക്തിപരമായി പ്രാർത്ഥിക്കാൻ പറ്റുന്നില്ലെ",
    youtubeId: "nHNB_NGtMl8",
    url: "https://www.youtube.com/watch?v=nHNB_NGtMl8",
  },
];
