// import { YouTube as YouTubeIcon } from "@mui/icons-material";
// import { Box, Grid, Typography } from "@mui/material";
// import React from "react";

// export const AboutUs = () => {
//   return (
//     <Box sx={{ p: 3 }}>
//       <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
//         <Typography variant="h4">About Us</Typography>
//       </Box>
//       <Typography
//         variant="h6"
//         sx={{
//           mt: 4, // margin-top for spacing
//           mb: 4, // margin-bottom for spacing
//           lineHeight: 1.6, // improve readability with line spacing
//           letterSpacing: "0.5px", // slight letter spacing
//           textAlign: "justify", // justify the text for even alignment
//           fontSize: {
//             xs: "1rem", // smaller font on extra small screens
//             sm: "1.1rem", // adjust for small screens
//             md: "1.2rem", // medium screens
//             lg: "1.25rem", // larger screens
//           },
//           color: "text.primary", // default primary text color
//         }}
//       >
//         <Box sx={{ mb: 2 }}>
//           <strong>Nanma Maram</strong> is a Christian Catholic community united
//           in faith, prayer, and fellowship, striving to grow in love for Jesus
//           Christ. Our mission is to inspire and guide individuals toward a
//           deeper
//           <strong> relationship with Jesus</strong>, bringing hope and
//           encouragement to all.
//         </Box>

//         <Box sx={{ mb: 2 }}>
//           Our active team supports our platforms, including YouTube, Facebook,
//           Instagram, and Spotify, where we share daily videos and faith-based
//           content. Every Sunday, we gather for a{" "}
//           <strong>online prayer meeting</strong> that includes the{" "}
//           <strong>
//             rosary, praise and worship, Bible sharing, adoration, deliverance
//           </strong>
//           and <strong>blessing by our Spiritual Father</strong>, helping us live
//           the Gospel more fully. .
//         </Box>

//         <Box sx={{ mb: 2 }}>
//           {" "}
//           <strong>
//             <em>
//               Join us as we grow together in Christ, rooted in love and led by
//               the Holy Spirit
//             </em>
//           </strong>
//         </Box>
//       </Typography>
//     </Box>
//   );
// };

import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Container,
  useTheme,
} from "@mui/material";

export const AboutUs = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box sx={{ p: { xs: 2, md: 4 } }}>
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <Typography variant="h3" component="h1" fontWeight="bold">
            About Us
          </Typography>
        </Box>

        {/* Spiritual Father Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 6,
          }}
        >
          <Avatar
            src={"/images/about/MaryMummy.jpeg"}
            alt="MaryMummy"
            sx={{
              width: { xs: 200, md: 250 },
              height: { xs: 200, md: 250 },
              mb: 2,
              boxShadow: theme.shadows[3],
            }}
          />
        </Box>
        {/* Content Card */}
        <Card
          elevation={3}
          sx={{
            borderRadius: 2,
            backgroundColor: "background.paper",
          }}
        >
          <CardContent sx={{ p: { xs: 3, md: 4 } }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1" paragraph sx={{ fontSize: "1.1rem" }}>
                <strong>Nanma Maram</strong> is a Christian Catholic community
                united in faith, prayer, and fellowship, striving to grow in
                love for Jesus Christ. Our mission is to inspire and guide
                individuals toward a deeper{" "}
                <strong>relationship with Jesus</strong>, bringing hope and
                encouragement to all.
              </Typography>

              <Typography variant="body1" paragraph sx={{ fontSize: "1.1rem" }}>
                Our active team supports our platforms, including YouTube,
                Facebook, Instagram, and Spotify, where we share daily videos
                and faith-based content. Every Sunday, we gather for a{" "}
                <strong>online prayer meeting</strong> that includes the{" "}
                <strong>
                  rosary, praise and worship, Bible sharing, adoration,
                  deliverance
                </strong>{" "}
                and <strong>blessing by our Spiritual Father</strong>, helping
                us live the Gospel more fully.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontStyle: "italic",
                  fontWeight: 600,
                  textAlign: "center",
                  fontSize: "1.1rem",
                  mt: 4,
                }}
              >
                Join us as we grow together in Christ, rooted in love and led by
                the Holy Spirit
              </Typography>
            </Box>
          </CardContent>
        </Card>

        {/* Spiritual Father Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 6,
          }}
        >
          <Avatar
            src={"/images/about/frJose.jpeg"}
            alt="Rev. Fr. Jose"
            sx={{
              width: { xs: 200, md: 250 },
              height: { xs: 200, md: 250 },
              mb: 2,
              boxShadow: theme.shadows[3],
            }}
          />
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Rev. Fr. Jose
          </Typography>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            Spiritual Father
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutUs;
