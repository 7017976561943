import Card from "../../components/Card";
import { shortPrayer } from "./constants";

export const Prayer = () => {
  return (
    <>
      <Card prayer={shortPrayer} />
    </>
  );
};
