import { Box, Container, Paper, Typography } from "@mui/material";
import React from "react";

const ComingSoon = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        bgcolor: "#f5f5f5",
        py: 8,
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: { xs: 2, sm: 4, md: 6 },
            textAlign: "center",
            borderRadius: 2,
            backgroundColor: "rgba(255, 255, 255, 0.95)",
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: { xs: "2rem", sm: "3rem", md: "3.75rem" },
            }}
          >
            Coming Soon
          </Typography>

          <Typography
            variant="h5"
            color="text.secondary"
            sx={{ mb: 4, fontSize: { xs: "1rem", sm: "1.25rem" } }}
          >
            All Glory to God and Forever!!!
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default ComingSoon;
