import {
  Instagram as InstagramIcon,
  Podcasts as PodcastsIcon,
  WhatsApp as WhatsAppIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useSpring, animated } from "react-spring";

export const SocialLinks = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const iconSize = { xs: "1.5rem", sm: "2rem", md: "2.5rem" };

  return (
    <animated.div style={fadeIn}>
      <Typography
        variant="h3"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          mb: 4,
          fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
        }}
      >
        Connect With Us
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ mb: 4, px: 2 }}
      >
        <IconButton color="primary" size="large">
          <a
            href="https://www.youtube.com/%E0%B4%A8%E0%B4%A8%E0%B5%8D%E0%B4%AE%E0%B4%AE%E0%B4%B0%E0%B4%82"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon sx={{ fontSize: iconSize, color: "#FF0000" }} />
          </a>
        </IconButton>

        {/* Instagram Icon with scale effect */}
        <IconButton color="primary" size="large">
          <a
            href="https://www.instagram.com/nanma_maram_/?igsh=dHExaGRtZmRvcTdl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon sx={{ fontSize: iconSize, color: "#E1306C" }} />
          </a>
        </IconButton>

        <IconButton color="primary" size="large">
          <a
            href="https://www.facebook.com/NanmaaMaram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon sx={{ fontSize: iconSize, color: "#1877F2" }} />
          </a>
        </IconButton>

        <IconButton color="primary" size="large">
          <a
            href="https://podcasters.spotify.com/pod/show/nanmamaram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PodcastsIcon sx={{ fontSize: iconSize, color: "#1DB954" }} />
          </a>
        </IconButton>

        <IconButton color="primary" size="large">
          <a
            href="https://chat.whatsapp.com/K5nGMx0mC5Y1sZ9AoGW8ej"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon sx={{ fontSize: iconSize, color: "#25D366" }} />
          </a>
        </IconButton>
      </Stack>
    </animated.div>
  );
};
