import React from "react";
import { Box, Container, Typography, Link, Grid } from "@mui/material";

export const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ bgcolor: "primary.main", color: "white", py: { xs: 4, md: 6 } }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              About Nanma Maram
            </Typography>
            <Typography variant="body2">
              Nanma Maram is a Christian faith community dedicated to nurturing
              spiritual growth and fostering connections among believers.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Typography variant="body2">
              <Link
                href="#"
                color="inherit"
                display="block"
                sx={{ textDecoration: "none" }}
              >
                Home
              </Link>
              <Link
                href="#"
                color="inherit"
                display="block"
                sx={{ textDecoration: "none" }}
              >
                About Us
              </Link>
              <Link
                href="#"
                color="inherit"
                display="block"
                sx={{ textDecoration: "none" }}
              >
                Events
              </Link>
              <Link
                href="#"
                color="inherit"
                display="block"
                sx={{ textDecoration: "none" }}
              >
                Contact
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2">
              Email: nanmamaramcommunity@gmail.com, nanmamaram0991@gmail.com
              <br />
              Phone: 08848586768
              <br />
              {/* Address: 123 Faith Street, Spiritual City, SC 12345 */}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} Nanma Maram. All rights reserved.
          </Typography>
          {/* <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            <Link color="inherit" href="#">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link color="inherit" href="#">
              Terms of Service
            </Link>
          </Typography> */}
        </Box>
      </Container>
    </Box>
  );
};
