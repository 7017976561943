import { alpha, createTheme, getContrastRatio } from "@mui/material";
const violetBase = "#7F00FF";
const violetMain = alpha(violetBase, 0.7);
export const theme = createTheme({
  palette: {
    primary: {
      main: "#002767",
      dark: "##001949",
      darker: "#00041c",
    },
    secondary: {
      main: "#ff4081",
    },
    violet: {
      main: violetMain,
      light: alpha(violetBase, 0.5),
      dark: alpha(violetBase, 0.9),
      contrastText:
        getContrastRatio(violetMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
  },
});
